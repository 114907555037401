import { AllFoerderantraege } from "../../../../model/foerderantraege.model";
import { FoerderantragDto, PaginatedResponseFoerderantragDto } from "../../generated";
import { mapFoerderantrag } from "./foerderantrag-dto.mapper";

/**
 * Maps the generated PageFoerderantragDto to the store used AllFoerderantraege
 * @param allFoerderantraegeExt the api call returned object
 * @returns returns a store friendly page object
 */
export const mapAllFoerderantraege = (allFoerderantraegeExt: Required<PaginatedResponseFoerderantragDto>): AllFoerderantraege => {
    return {
        totalElements: allFoerderantraegeExt.totalElements,
        totalPages: allFoerderantraegeExt.totalPages,
        size: allFoerderantraegeExt.size,
        content: allFoerderantraegeExt.content.map((foerderantrag) => mapFoerderantrag(foerderantrag as Required<FoerderantragDto>)),
        numberOfElements: allFoerderantraegeExt.numberOfElements,
        empty: allFoerderantraegeExt.empty,
    };
}
