import { Injectable, inject } from '@angular/core';
import { AllFoerderantraege, FoerderantraegeStore, Foerderantrag, Status } from '../../../model/foerderantraege.model';
import { FoerderantragDto, FoerderantragUnternehmenControllerService, PaginatedResponseFoerderantragDto } from '../generated';
import { Observable, map } from 'rxjs';
import { mapFoerderantrag } from '../mapper/foerderantraege/foerderantrag-dto.mapper';
import { mapAllFoerderantraege } from '../mapper/foerderantraege/page-foerderantrag-dto.mapper';

/**
 * The service to interact with the foerderantrag-controller-unternehmen
 */
@Injectable({
    providedIn: 'root',
})
export class UnternehmenFoerderantraegeApiService {
    private foerderantraegeUnternehmenControllerService = inject(FoerderantragUnternehmenControllerService);
    private FoerderantragUnternehmenControllerApi = inject(FoerderantragUnternehmenControllerService);

    /**
     * Service function to get all foerderantraege from the backend for the current user
     *
     * @param foerderantraegeStore gets the complete store to build the query in a sub function
     * @param unternehmenId the id of the selected company
     * @returns returns the Observable<AllFoerderantraege> of the API call with the correctly mapped object
     */
    getAllFoerderantraege(foerderantraegeStore: FoerderantraegeStore, unternehmenId: number): Observable<AllFoerderantraege> {
        const { foerderartFilter, page, searchParameter, size, sortingArray, statusFilter } = foerderantraegeStore;
        const newSortArray: string[] = [];
        for (const sort of sortingArray) {
            newSortArray.push(sort.column + ',' + sort.sortDirection.toString());
        }
        const param: { page: number; size: number; sort: string[]; foerderart?: string; searchparameter?: string; status?: Status } = {
            page: page,
            size: size,
            sort: newSortArray,
        };
        foerderartFilter ? (param['foerderart'] = foerderartFilter) : '';
        searchParameter ? (param['searchparameter'] = searchParameter) : '';
        statusFilter ? (param['status'] = statusFilter) : '';

        return this.foerderantraegeUnternehmenControllerService
            .findFoerderantraegeForCurrentNutzer({ ...param, unternehmenId })
            .pipe(map((allFoerderantraegeExt) => mapAllFoerderantraege(allFoerderantraegeExt as Required<PaginatedResponseFoerderantragDto>)));
    }

    /**
     * Service function to get one foerderantrag
     * @param foerderantragId the id of the requested foerderantrag
     * @param unternehmenId the id of the selected company
     * @returns returns the Observable<Foerderantrag> of the API call with the correctly mapped object
     */
    getFoerderantragById(foerderantragId: number, unternehmenId: number): Observable<Foerderantrag> {
        return this.foerderantraegeUnternehmenControllerService
            .findFoerderantragById({ id: foerderantragId, unternehmenId: unternehmenId })
            .pipe(map((foerderantragExt) => mapFoerderantrag(foerderantragExt as Required<FoerderantragDto>)));
    }

    /**
     * Service function to create a new foerderantrag in the backend
     * @param foerderartId the id of the foerderart the antrag is of
     * @param unternehmenId the id of the unternehmen the antrag is for
     * @returns the antragsnummer of the created foerderantrag
     */
    createFoerderantrag(foerderartId: number, unternehmenId: number): Observable<string> {
        return this.foerderantraegeUnternehmenControllerService
            .createFoerderantrag({ requestBody: foerderartId, unternehmenId })
            .pipe(map((antragsnummerDTO) => antragsnummerDTO.antragsnummer));
    }

    /**
     * Service function to get all foerderantraege from the backend for the given user
     * @param unternehmenId Id of the company
     * @param nutzerIdpId Id of the user for which the foerderantraege should be retrieved
     * @returns List of all Foerderantraege for the given user
     */
    getFoerderantrageForNutzerInUnternehmen(unternehmenId: number, nutzerIdpId: string): Observable<Foerderantrag[]> {
        return this.FoerderantragUnternehmenControllerApi.getFoerderantrageForNutzerInUnternehmen({ unternehmenId, nutzerIdpId }).pipe(
            map((dtoArray: FoerderantragDto[]) => dtoArray.map((dto) => mapFoerderantrag(dto as Required<FoerderantragDto>))),
        );
    }
}
