import { TableAction } from './data-table.model';
import { Foerderart } from './foerderbereich.model';
import { Nebenantragsart } from './nebenantraege.model';
import { SortParameter } from './store.model';

export enum Status {
    ANTRAG_ANGELEGT = 'ANTRAG_ANGELEGT',
    ANTRAG_EINGEREICHT = 'ANTRAG_EINGEREICHT',
    PRUEFUNG = 'PRUEFUNG',
    PRUEFUNG_ERNEUT = 'PRUEFUNG_ERNEUT',
    ANTRAG_ZURUECKGEZOGEN = 'ANTRAG_ZURUECKGEZOGEN',
    ANTRAG_DATENNACHFORDERUNG = 'ANTRAG_DATENNACHFORDERUNG',
    KOMMISSION = 'KOMMISSION',
    KOMMISSION_DATENNACHREICHUNG = 'KOMMISSION_DATENNACHREICHUNG',
    ABLEHNUNG = 'ABLEHNUNG',
    ZUSAGE_PRUEFUNG = 'ZUSAGE_PRUEFUNG',
    ANTRAG_BEWILLIGT = 'ANTRAG_BEWILLIGT',
    VERWENDUNGSNACHWEISPRUEFUNG = 'VERWENDUNGSNACHWEISPRUEFUNG',
    WIDERSPRUCH = 'WIDERSPRUCH',
    TILGUNG = 'TILGUNG',
    ABGESCHLOSSEN = 'ABGESCHLOSSEN',
    WIRD_EINGEREICHT = 'WIRD_EINGEREICHT',
    ABHOLBEREIT = 'ABHOLBEREIT',
    ABGEHOLT = 'ABGEHOLT',
    DATENUEBERNAHME = 'DATENUEBERNAHME',
    DATENUEBERNAHME_ABGESCHLOSSEN = 'DATENUEBERNAHME_ABGESCHLOSSEN',
    ANTRAG_GELOESCHT = 'ANTRAG_GELOESCHT',
}

export type Foerderantrag = {
    id: number;
    status: Status;
    statusString?: string;
    antragsteller: string;
    gatewayId: string;
    formularShortname: string;
    fmsUuid: string;
    antragsnummer: string;
    foerdernummer: string;
    aktualisiertAm: string;
    eingereichtAm: string;
    aktualisiertVon: string;
    'foerderart.bezeichnung': string;
    projektname: string;
    fristFreischaltung?: string;
    fristEnde?: string;
};

export type AllFoerderantraege = {
    totalElements: number;
    totalPages: number;
    size: number;
    content: Foerderantrag[];
    numberOfElements: number;
    empty: boolean;
};

export type FoerderantraegeStore = {
    searchParameter: string;
    foerderartFilter: string;
    statusFilter: Status | null;
    page: number;
    size: number;
    sortingArray: SortParameter[];
    allFoerderantraege: AllFoerderantraege | null;
    displayedDetailsFoerderantrag: Foerderantrag | null;
    isLoading: boolean;
    tableActions: TableAction[][];
    foerderartenInUse: Foerderart[];
};


//TODO: FFA-305 Remove hardcoded Values
export const WIDERRUFFORMULAR: Nebenantragsart = {
    bezeichnung: 'Widerspruch',
    gatewayShortname: 'FFA_190',
    id: 2
}

//TODO: FFA-305 Remove hardcoded Values
export const RUECKNAHME: Nebenantragsart = {
    bezeichnung: 'Antragsrücknahme',
    gatewayShortname: 'FFA_191',
    id: 1
}

//TODO: FFA-305 remove hardcoded Stammdaten Formular
export const STAMMDATEN: Foerderart = {
    id: 47,
    bezeichnung: 'Stammdatenerhebung',
    gatewayShortname: 'FFA_020',
    foerderbereichBezeichnung: 'Sonderanträge',
    rechtsgrundlage: '-',
}