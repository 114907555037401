import { inject, Injectable } from "@angular/core";
import { PaginatedResponsePostkorbNachrichtDto, PostkorbNachrichtCommonControllerService } from "../generated";
import { Observable, map } from "rxjs";
import { PagePostkorbNachricht } from "../../../model/postkorb.model";
import { mapPagePostkorbNachricht } from "../mapper/postkorb/PagePostkorbNachrichtDto.mapper";

@Injectable({ providedIn: 'root' })
export class PostkorbCommonApiService {
    private postkorbCommonControllerService = inject(PostkorbNachrichtCommonControllerService);

    getAllInboxMessages(page: number, size: number, sort: string[], searchparameter?: string,): Observable<PagePostkorbNachricht> {
        return this.postkorbCommonControllerService.getPostkorbNachrichten({ page, size, sort, searchparameter })
            .pipe(map((pagePostkorbNachrchrichtDto) => mapPagePostkorbNachricht(pagePostkorbNachrchrichtDto as Required<PaginatedResponsePostkorbNachrichtDto>)));
    }

    updateGelesenStatus(postkorbNachrichtId: number, gelesenStatus: boolean): Observable<boolean> {
        return this.postkorbCommonControllerService.updateGelesenStatus1({ postkorbNachrichtId, requestBody: gelesenStatus });
    }
}
