
import { Nebenantragsart } from "../../../../model/nebenantraege.model";
import { NebenantragsartCountDto } from "../../generated";

/**
 * Maps the generated NebenantragsartCountDto to the store used NebenantragInfo
 * @param nebenantragsartDto the API returned nebenantragsart
 * @returns returns the store friendly NebenantragInfo
 */
export const mapNebenantragCountInfo = (nebenantragsartDto: NebenantragsartCountDto): Nebenantragsart => {
    return {
        amount: nebenantragsartDto.amount,
        bezeichnung: nebenantragsartDto.bezeichnung,
        gatewayShortname: nebenantragsartDto.gatewayShortname,
        id: nebenantragsartDto.id,
        sortierPosition: nebenantragsartDto.sortierPosition,
    };
}
