import { Observable, map } from 'rxjs';
import { NebenantraegeContentPage, NebenantraegeTableModifiers, Nebenantragsart } from '../../../model/nebenantraege.model';
import {
    NebenantragUnternehmenControllerService,
    NebenantragsartUnternehmenControllerService,
    PaginatedResponseNebenantragDto,
} from '../generated';
import { Injectable, inject } from '@angular/core';
import { Status } from '../../../model/foerderantraege.model';
import { mapNebenantragCountInfo } from '../mapper/nebenantraege/nebenantragsart-count-dto.mapper';
import { mapNebenantragInfo } from '../mapper/nebenantraege/nebenantragsart-dto.mapper';
import { mapNebenantraegePage } from '../mapper/nebenantraege/page-nebenantrag-dto.mapper';

@Injectable({
    providedIn: 'root',
})
/**
 * The service for a company user to get the nebenantraege data
 */
export class UnternehmenNebenantraegeAPIService {

    private nebenantragsartUnternehmenControllerService = inject(NebenantragsartUnternehmenControllerService);
    private nebenantragUnternehmenControllerService = inject(NebenantragUnternehmenControllerService);

    /**
     * get the nebenantragsarten that are createable for the given foerderantrag
     * @param foerderantragId the id of the foerderantrag for which the nebebnantraege should be returned
     * @param unternehmenId the id of the company that is sending the request
     * @returns a list of nebenantragsarten that can be created for the foerderantrag
     */
    getPermittedNebenantragsart(foerderantragId: number, unternehmenId: number): Observable<Nebenantragsart[]> {
        return this.nebenantragsartUnternehmenControllerService
            .findPermittedNebenantragsart({
                foerderantragId: foerderantragId,
                unternehmenId: unternehmenId,
            })
            .pipe(map((nebenantragsartDtoArray) => nebenantragsartDtoArray
                .map((nebenantragsartDto) => mapNebenantragInfo(nebenantragsartDto))));
    }

    /**
     * get the nebenantragsarten that are existing on the given foerderantrag
     * @param foerderantragId the id of the foerderantrag for which the nebebnantraege should be returned
     * @param unternehmenId the id of the company that is sending the request
     * @returns a list of nebenantragsarten that are existing on the foerderantrag
     */
    getFilledNebenantragsart(foerderantragId: number, unternehmenId: number): Observable<Nebenantragsart[]> {
        return this.nebenantragsartUnternehmenControllerService
            .findNebenantragsartCountDto({
                foerderantragId: foerderantragId,
                unternehmenId: unternehmenId,
            })
            .pipe(
                map((nebenantragsartDtoArray) =>
                    nebenantragsartDtoArray
                        .map((nebenantragsartDto) => mapNebenantragCountInfo(nebenantragsartDto)),
                ),
            );
    }

    /**
     * get one page of one kind of nebenantragart for the given request modifiers
     * @param nebenantraegeTableModifiers the modifiers for the query that should be added
     * @param foerderantragId the id of the foerderantrag for that the nebenantraege should be looked for
     * @param unternehmenId the id of the company that is sending the request
     * @returns one page of the nebenantraege for a nebenantraegeart for a foerderantrag
     */
    getNebenantraegePage(
        nebenantraegeTableModifiers: NebenantraegeTableModifiers,
        foerderantragId: number,
        unternehmenId: number,
    ): Observable<NebenantraegeContentPage> {
        const { nebenantragsartId: nebenantragsartId, searchParameter, sortingArray, size, page, statusFilter } = nebenantraegeTableModifiers;
        const params: {
            searchparameter?: string;
            sort: string[];
            status?: Status;
        } = { sort: sortingArray.map((sortingElement) => sortingElement.column + ',' + sortingElement.sortDirection) };

        searchParameter !== '' ? (params.searchparameter = searchParameter) : '';
        statusFilter !== null ? (params.status = statusFilter) : '';

        return this.nebenantragUnternehmenControllerService
            .findNebenantragReferencingFoerderantrag({
                foerderantragId,
                unternehmenId,
                nebenantragsartId,
                size,
                page,
                ...params,
            })
            .pipe(
                map((nebenantraegePageDto) => {
                    return mapNebenantraegePage(nebenantraegePageDto as Required<PaginatedResponseNebenantragDto>);
                }),
            );
    }

    /**
     * Create a new nebenantrag for the given request modifiers
     * @param nebenantragsartId the id of the nebenantragsart for that the nebenantrag should be created
     * @param hauptantragId the id of the foerderantrag for that the nebenantrag should be created
     * @param unternehmenId the id of the company that is sending the requestreate
     * @returns the antragsnummer of the created nebenantrag
     */
    createNebenantrag(nebenantragsartId: number, hauptantragId: number, unternehmenId: number): Observable<string> {
        return this.nebenantragUnternehmenControllerService.createNebenantrag({
            requestBody: { nebenantragsartId, hauptantragId },
            unternehmenId,
        }).pipe(map(((antragsnummerDTO) => antragsnummerDTO.antragsnummer)));
    }

}
