import { Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AllUnternehmenNutzerStore, AllUnternehmenNutzerPage, NutzerRollen, AllUnternehmenNutzer } from '../../../model/all-unternehmen-nutzer.model';
import { NutzerUnternehmenControllerService, PaginatedResponseNutzerAdminResponseDto } from '../generated';
import { Foerderbereich } from '../../../model/foerderbereich.model';
import { mapBenutzerPage } from '../mapper/all-unternehmen-nutzer/page-nutzer-admin-response-dto.mapper';

@Injectable({
    providedIn: 'root',
})
export class AllUnternehmenNutzerApiService {
    private unternehmenNutzerUnternehmenControllerService = inject(NutzerUnternehmenControllerService);

    /**
     * Service function to fetch all users.
     * @param allUnternehmenNutzerStore alleBeutzerStore The store object containing all user data.
     * @param unternehmenId The ID of the company whose users are to be retrieved.
     * @returns An observable for the user page.
     */
    getAllUnternehmenNutzer(
        allUnternehmenNutzerStore: AllUnternehmenNutzerStore,
        unternehmenId: number,
        allFoerderbereiche: Foerderbereich[],
    ): Observable<AllUnternehmenNutzerPage> {
        const {
            sortingArray,
            page,
            size,
            searchParameter,
            activeFilter: aktiv,
            roleFilter: rollen,
            foerderbereichFilter,
        } = allUnternehmenNutzerStore.allUnternehmenNutzerTable;
        const newSortArray: string[] = [];
        for (const sort of sortingArray) {
            newSortArray.push(sort.column + ',' + sort.sortDirection.toString());
        }
        const param: {
            unternehmenId: number;
            searchparameter?: string;
            aktiv?: boolean;
            rollen?: ('UNTERNEHMEN_MITARBEITER' | 'UNTERNEHMEN_ADMIN' | 'FFA_MITARBEITER' | 'FFA_ADMIN')[];
            foerderbereiche?: number[];
            page: number;
            size: number;
            sort: string[];
        } = {
            page: page,
            size: size,
            sort: newSortArray,

            unternehmenId,
        };

        if (foerderbereichFilter) {
            const foerderbereichEx = allFoerderbereiche.find((foerderbereich) => foerderbereich.bezeichnung === foerderbereichFilter);
            if (foerderbereichEx) {
                param['foerderbereiche'] = [foerderbereichEx.id];
            }
        }
        searchParameter ? (param['searchparameter'] = searchParameter) : '';
        aktiv !== null ? (param['aktiv'] = aktiv) : '';
        rollen
            ? (param['rollen'] = rollen.map(
                (rolle) => NutzerRollen[rolle as 'FFA Mitarbeiter*in' | 'FFA Admin' | 'Mitarbeiter*in' | 'Admin'],
            ))
            : '';
        return this.unternehmenNutzerUnternehmenControllerService
            .getNutzerOfUnternehmen({ ...param })
            .pipe(map((alleBenutzerExt) => mapBenutzerPage(alleBenutzerExt as Required<PaginatedResponseNutzerAdminResponseDto>)));
    }

    getNewNutzer(unternehmenId: number, email: string): Observable<AllUnternehmenNutzer> {
        return this.unternehmenNutzerUnternehmenControllerService
            .getNutzerOfUnternehmen({ unternehmenId, page: 0, size: 20, searchparameter: email })
            .pipe(
                map((allNutzer) => {
                    const nutzer = mapBenutzerPage(allNutzer as Required<PaginatedResponseNutzerAdminResponseDto>);
                    return nutzer.allUnternehmenNutzer[0];
                }),
            );
    }
}
