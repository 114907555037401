import { NebenantraegeContentPage } from "../../../../model/nebenantraege.model";
import { NebenantragDto, PaginatedResponseNebenantragDto } from "../../generated";
import { mapNebenantraegeDetail } from "./nebenantrag-dto.mapper";

/**
 * Maps the generated Required<PageNebenantragDto> (required because no fields are optional in the return) to the store used NebenantraegePage
 * @param nebenantragsartDto the API returned nebenantraege page
 * @returns returns the store friendly NebenantraegePage
 */
export const mapNebenantraegePage = (nebenantraegePageDto: Required<PaginatedResponseNebenantragDto>): NebenantraegeContentPage => {
    return {
        empty: nebenantraegePageDto.empty,
        nebenantraegeDetails: nebenantraegePageDto.content.map((nebenantragDto) =>
            mapNebenantraegeDetail(nebenantragDto as Required<NebenantragDto>),
        ),
        numberOfElements: nebenantraegePageDto.numberOfElements,
        totalElements: nebenantraegePageDto.totalElements,
        totalPages: nebenantraegePageDto.totalPages,
    };
}
