import { DokumenteUnternehmenPage } from "../../../../model/dokumente-unternehmen.model";
import { FfaDokumentDto, PaginatedResponseFfaDokumentDto } from "../../generated";
import { mapDokumentUnternehmen } from "./ffa-dokument-dto.mapper";

export const mapDokumenteUnternehmenDataTable = (pageFfaDokumente: Required<PaginatedResponseFfaDokumentDto>): DokumenteUnternehmenPage => {
    return {
        totalElements: pageFfaDokumente.totalElements,
        content: pageFfaDokumente.content.map((ffaDokument) => mapDokumentUnternehmen(ffaDokument as Required<FfaDokumentDto>)),
    }
}
