import { AllUnternehmenNutzerPage } from '../../../../model/all-unternehmen-nutzer.model';
import { NutzerAdminResponseDto, PaginatedResponseNutzerAdminResponseDto } from '../../generated';
import { mapAllUnternehmenNutzer } from './nutzer-admin-response-dto.mapper';

export const mapBenutzerPage = (alleBenutzerExt: Required<PaginatedResponseNutzerAdminResponseDto>): AllUnternehmenNutzerPage => {
    const benutzerPage: AllUnternehmenNutzerPage = {
        totalElements: alleBenutzerExt.totalElements,
        allUnternehmenNutzer: alleBenutzerExt.content.map((adminNutzerDto: NutzerAdminResponseDto) =>
            mapAllUnternehmenNutzer(adminNutzerDto as Required<NutzerAdminResponseDto>),
        ),
    };
    return benutzerPage;
};
