import { inject, Injectable } from "@angular/core";
import { DokumenteUnternehmenControllerService, PaginatedResponseFfaDokumentDto } from "../generated";
import { map, Observable } from "rxjs";
import { AntragsDokument, DokumentContent, DokumenteUnternehmenPage } from "../../../model/dokumente-unternehmen.model";
import { mapDokumenteUnternehmenDataTable } from "../mapper/dokumente-unternehmen/page-ffa-dokumente-dto.mapper";
import { mapDokumentContent } from "../mapper/dokumente-unternehmen/dokument-content-dto.mapper";
import { mapAntragsDokument } from "../mapper/dokumente-unternehmen/antrags-dokument-dto.mapper";

/**
 * Service to handle the api calls to the Dokumente Unternehmen controller
 */
@Injectable({
    providedIn: 'root'
})
export class DokumenteUnternehmenApiService {
    private dokumenteUnternehmenControllerService = inject(DokumenteUnternehmenControllerService);

    /**
     * Get the page of FFA Dokumente for the company and foerderantrag
     * @param unternehmenId The id of the active company
     * @param foerderantragId The id of the displayed foerderantrag
     * @param querryParams The parameters that are needed for a page list request
     * @returns the content and total elements of the displayed list
     */
    getFfaDokumenteForFoerderantrag(
        unternehmenId: number,
        foerderantragId: number,
        querryParams: {
            page: number,
            size: number,
            sort: string[],
            searchparameter?: string,
            onlyBescheide?: boolean,
            onlyUngeleseneBescheide?: boolean,
            noBescheide?: boolean,
        }
    ): Observable<DokumenteUnternehmenPage> {
        return this.dokumenteUnternehmenControllerService.getFfaDokumenteForFoerderantrag({ unternehmenId, foerderantragId, ...querryParams, }).pipe(
            map((pageFfaDokumente) => mapDokumenteUnternehmenDataTable(pageFfaDokumente as Required<PaginatedResponseFfaDokumentDto>))
        );
    }

    /**
     * The request to get the content of a dokument so that it can be downloaded
     * @param unternehmenId The id of the active company
     * @param foerderantragId The id of the displayed foerderantrag
     * @param dokumentId The id of the requested dokument
     * @returns returns the content and the name of the dokument to be build and then downloaded.
     */
    getDokumentFile(unternehmenId: number, foerderantragId: number, dokumentId: number): Observable<DokumentContent> {
        return this.dokumenteUnternehmenControllerService.getDokumentContent({ unternehmenId, foerderantragId, dokumentId }).pipe(
            map(dokumentContent => mapDokumentContent(dokumentContent))
        );
    }

    /**
     * Get the antrags pdf
     * @param unternehmenId the unternehmen for which the antrag is registered
     * @param antragId the id of the antrag that should be retrived
     * @returns the antrags pdf as a blob
     */
    getAntragsPDF(unternehmenId: number, antragId: number): Observable<AntragsDokument> {
        return this.dokumenteUnternehmenControllerService.getPdfDokumentOfAntrag({ unternehmenId, foerderantragId: antragId }).pipe(
            map((antragsDokumentDto) => mapAntragsDokument(antragsDokumentDto))
        );
    }


    updateDokumentLesebestaetigung(unternehmenId: number, dokumentId: number): Observable<void> {
        return this.dokumenteUnternehmenControllerService.markDokumentAsGelesen({ dokumentId, unternehmenId });
    }
}
