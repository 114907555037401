import { Injectable, inject } from '@angular/core';
import { AllFoerderantraege, FoerderantraegeStore, Foerderantrag, Status } from '../../../model/foerderantraege.model';
import { FoerderantragDto, FoerderantragFfaControllerService, PaginatedResponseFoerderantragDto } from '../generated';
import { Observable, map } from 'rxjs';
import { mapAllFoerderantraege } from '../mapper/foerderantraege/page-foerderantrag-dto.mapper';
import { mapFoerderantrag } from '../mapper/foerderantraege/foerderantrag-dto.mapper';

/**
 * The service to interact with the foerderantrag-ffa-controller
 */
@Injectable({
    providedIn: 'root',
})
export class FfaFoerderantraegeApiService {
    private foerderantraegeFfaControllerService = inject(FoerderantragFfaControllerService);

    /**
     * Service function to get all foerderantraege from the backend for a ffa user
     *
     * @param foerderantraegeStore gets the complete store to build the query in a sub function
     * @returns returns the Observable<AllFoerderantraege> of the API call with the correctly mapped object
     */
    getAllFoerderantraege(foerderantraegeStore: FoerderantraegeStore): Observable<AllFoerderantraege> {
        const { foerderartFilter, page, searchParameter, size, sortingArray, statusFilter } = foerderantraegeStore;
        const newSortArray: string[] = [];
        for (const sort of sortingArray) {
            newSortArray.push(sort.column + ',' + sort.sortDirection.toString());
        }
        const param: { page: number; size: number; sort: string[]; foerderart?: string; searchparameter?: string; status?: Status } = {
            page: page,
            size: size,
            sort: newSortArray,
        };
        foerderartFilter ? (param['foerderart'] = foerderartFilter) : '';
        searchParameter ? (param['searchparameter'] = searchParameter) : '';
        statusFilter ? (param['status'] = statusFilter) : '';

        return this.foerderantraegeFfaControllerService
            .findAllWithParams({ ...param })
            .pipe(map((allFoerderantraegeExt) => mapAllFoerderantraege(allFoerderantraegeExt as Required<PaginatedResponseFoerderantragDto>)));
    }

    /**
     * Service function to get one foerderantrag
     * @param foerderantragId The id of the requested foerderantrag
     * @returns returns the Observable<Foerderantrag> of the API call with the correctly mapped object
     */
    getFoerderantragById(foerderantragId: number): Observable<Foerderantrag> {
        return this.foerderantraegeFfaControllerService
            .findFoerderantragById1({ id: foerderantragId })
            .pipe(map((foerderantragExt) => mapFoerderantrag(foerderantragExt as Required<FoerderantragDto>)));
    }

}
