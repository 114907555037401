import { PagePostkorbNachricht } from "../../../../model/postkorb.model";
import { PaginatedResponsePostkorbNachrichtDto, PostkorbNachrichtDto } from "../../generated";
import { mapPostkorbNachricht } from "./PostkorbNachrichtDto.mapper";

/**
 * Map the generated Dto to our frontend type
 * @param pagePostkorbNachrichtDto the generated Type
 * @returns our internal type
 */
export const mapPagePostkorbNachricht = (pagePostkorbNachrichtDto: Required<PaginatedResponsePostkorbNachrichtDto>): PagePostkorbNachricht => ({
    totalElements: pagePostkorbNachrichtDto.totalElements,
    totalPages: pagePostkorbNachrichtDto.totalPages,
    content: pagePostkorbNachrichtDto.content.map((postkorbNachricht) => mapPostkorbNachricht(postkorbNachricht as Required<PostkorbNachrichtDto>)),
    numberOfElements: pagePostkorbNachrichtDto.numberOfElements,
    empty: pagePostkorbNachrichtDto.empty,
});
